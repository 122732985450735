import React from 'react'
import { CenteredResponsiveContainer, Layout } from '@/components/Layout'
import { SEO } from '@/components/SEO'
import tw from 'twin.macro'

const Section = tw.div`mb-16`
const SectionHeading = tw.h2`text-2xl mb-4`
const SubHeading = tw.h3`text-lg mb-3 ml-12`
const List = tw.ul`list-disc`
const ListItem = tw.li`ml-6`
const Paragraph = tw.div`my-3`
const Anchor = tw.a`text-jump-red`
const Italic = tw.span`italic`
const Bold = tw.span`font-semibold`

export default function Privacy() {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <section>
        <CenteredResponsiveContainer>
          <h1 className="text-3xl mb-1 ml-6">DATA PROTECTION AND PRIVACY POLICY</h1>
          <h4 className="text-lg mb-6 ml-12">January 2023</h4>

          <Section>
            <Paragraph>
              Jump Operations, LLC and its subsidiaries and affiliates (collectively, “Jump” or
              “we”) respect the privacy of Personal Data and are committed to protecting Personal
              Data. This Data Protection and Privacy Policy (this “Policy”) describes the policies
              and procedures Jump has implemented to protect Personal Data Processed by Jump in
              accordance with applicable Data Protection Laws. This Policy also describes how Jump
              collects, uses, and discloses Personal Data from your interactions with us, including
              when you visit Jump websites and other online platforms as well as your privacy
              rights. This Policy does not apply to the Personal Data of Jump’s employees,
              contractors, or job applicants, which are subject to separate data protection
              policies.
            </Paragraph>
            <Paragraph>
              Jump has voluntarily opted to apply the protections and obligations outlined in this
              Policy to all Data Subjects. However, such protections and obligations may be beyond
              the legal requirements of the jurisdiction of certain Data Subjects. Data Protection
              Laws vary widely across jurisdictions and while Jump will strive to meet this Policy
              for all Data Subjects, the Data Protection Laws for the Data Subject ultimately govern
              the protections conferred in the specific jurisdiction applicable to that Data
              Subject. Additionally, there may be certain rights applicable to you for which we may
              provide separate notice informing you of your rights and how to adequately exercise
              them.
            </Paragraph>
          </Section>

          <Section>
            <SectionHeading>DEFINITIONS</SectionHeading>
            <Paragraph>
              <Bold>"Controller"</Bold>&nbsp;means a natural or legal person, public authority,
              agency, or other body that, independently or jointly with others, determines the
              purpose and means of Processing Personal Data, as defined in Data Protection Laws. For
              purposes of applicable Data Protection Laws, the Controller of the Personal Data
              Processing described below is Jump Operations, LLC and its subsidiaries and
              affiliates, and with regard to certain Processes, Jump may act as joint Controller
              with a third party.
            </Paragraph>
            <Paragraph>
              <Bold>"Data Protection Laws"</Bold>&nbsp;means privacy laws and regulations that apply
              to Jump’s Processing of your Personal Data. This primarily includes the Regulation
              (EU) 2016/679 (GDPR), the Singapore Personal Data Protection Act 2012 (PDPA), the
              Chinese Cyber Security Law (the CS Law), the Chinese Personal Information Protection
              Law (PIPL), and applicable state and federal privacy laws in the United States, but
              may extend to other applicable privacy laws.
            </Paragraph>
            <Paragraph>
              <Bold>"Data Subject"</Bold>&nbsp;refers to you, as a natural person who can be
              identified, directly or indirectly, by reference to your Personal Data.
            </Paragraph>
            <Paragraph>
              <Bold>"Personal Data"</Bold>
              &nbsp;means any information attributable to an identified or identifiable natural
              person (a Data Subject), as defined in Data Protection Laws. Personal Data does not
              include data where the identity has been removed (anonymous data). Personal Data shall
              encompass Special Category Data.
            </Paragraph>
            <Paragraph>
              <Bold>"Process"</Bold> or
              <Bold>"Processing"</Bold> or
              <Bold>"Processed"</Bold> or
              <Bold>"Processes"</Bold>
              &nbsp;means, as applicable, any operation or set of operations performed upon Personal
              Data, whether or not by automatic means, such as collecting, recording, using,
              organizing, structuring, storing, adapting or altering, retrieving, disclosing by
              transmission, disseminating or otherwise making available, aligning or combining,
              restricting, erasing, or purging.
            </Paragraph>
            <Paragraph>
              <Bold>"Processor"</Bold>&nbsp;means a natural or legal person, public authority,
              agency, or other body that Processes Personal Data on behalf of a Controller, as
              defined in Data Protection Laws. A Processors’ activities are limited to the more
              “technical” aspects of a Process and do not include the exercise of professional
              judgment or significant decision-making in relation to Personal Data. Processors may
              include third party service providers, applications, or agencies utilized by Jump in
              the course of business.
            </Paragraph>
            <Paragraph>
              <Bold>"Special Category Data"</Bold>&nbsp;(also known as{' '}
              <Bold>“Sensitive Personal Data”</Bold>) means Personal Data revealing racial or ethnic
              origin, criminal history, political opinions, religious or philosophical beliefs,
              sexual orientation, trade union membership, precise geolocation, or health, genetic,
              or biometric data, or data pertaining to a child or minor.
            </Paragraph>
          </Section>

          <Section>
            <SectionHeading>COLLECTION OF PERSONAL DATA</SectionHeading>
            <SubHeading>a. Website Data </SubHeading>
            <Paragraph>
              Jump may collect your Personal Data on our websites. The technologies we use to track
              your movements around our websites may include cookies, tracking scripts and pixels,
              or tagging technologies, which we may employ to understand your preferences, improve
              your experience on our websites, etc.{' '}
            </Paragraph>
            <Paragraph>
              Most internet browsers automatically accept cookies. However, you can configure your
              browser so that no cookies are stored on your end device or a message always appears
              when you receive a new cookie. Further information can be found on&nbsp;
              <Anchor href="http://www.aboutcookies.org">http://www.aboutcookies.org</Anchor>.
            </Paragraph>
            <Paragraph>
              Please note that disabling cookies may prevent you from using all the features of our
              websites.
            </Paragraph>
            <SubHeading>b. Tracking Tools</SubHeading>
            <Paragraph>
              We use tracking tools, such as Google Analytics, which generate information about your
              use of our websites. Such information may include a navigation path that you follow on
              our websites; how long you spend on our websites and subpages; the subpage from which
              you leave our websites; the country, region, or city from where you access our
              websites; returning or new visitor; and host name of the accessing computer (IP
              address).
            </Paragraph>
            <Paragraph>
              This information is transmitted to Google servers in the USA and stored there. Such
              information is used to evaluate the use of our websites, compile reports on Website
              activities, and provide other services associated with Website and internet use.
              Google may transfer such information to third parties where required to do so by law,
              or where such third parties Process the information on Google’s behalf. For additional
              information, see Google’s Privacy Policy at&nbsp;
              <Anchor href="https://policies.google.com/privacy?gl=US&hl=en">
                https://policies.google.com/privacy?gl=US&hl=en
              </Anchor>
              .
            </Paragraph>
          </Section>

          <Section>
            <SectionHeading>USE OF PERSONAL DATA</SectionHeading>
            <Paragraph>
              Jump limits the Personal Data it collects to what is necessary. Jump will only use
              Personal Data when Data Protection Laws allow Jump to do so. Personal Data shall be
              Processed in a manner that is adequate, relevant, and not excessive in relation to the
              intended business purpose(s) of such Processing.
            </Paragraph>
            <Paragraph>
              Depending on the nature of Jump’s interactions with you, we may Process your Personal
              Data for some or all of the following purposes:
            </Paragraph>
            <List>
              <ListItem>To respond to your questions and requests;</ListItem>
              <ListItem>
                To provide you with access to certain areas and features of our websites;
              </ListItem>
              <ListItem>For record keeping purposes;</ListItem>
              <ListItem>
                Data Subject giving consent to the Processing of his or her Personal Data for a
                specific purpose(s);{' '}
              </ListItem>
              <ListItem>
                Processing is necessary for the performance of a contract to which the Data Subject
                is party or in order to take steps at the request of the Data Subject prior to
                entering into a contract;
              </ListItem>
              <ListItem>
                Processing is necessary for compliance with a legal obligation to which Jump is
                subject;
              </ListItem>
              <ListItem>
                Processing is necessary to protect the vital interests of the Data Subject or of
                another natural person;{' '}
              </ListItem>
              <ListItem>
                Processing is necessary for the purposes of the legitimate interests pursued by Jump
                or a third party, except where such interests are overridden by the interests or
                fundamental rights and freedoms of the Data Subject which require protection of
                Personal Data;{' '}
              </ListItem>
              <ListItem>
                For security and safety purposes, such as protecting against cyber threats;
                detecting, investigating, or preventing fraud and other illegal activities; and
                responding to legal and regulatory compliance obligations; and/or
              </ListItem>
              <ListItem>
                For other lawful purposes disclosed at the time we collect your Personal Data or
                otherwise with your consent.
              </ListItem>
            </List>
            <Paragraph>
              Jump does not use automated decision-making in Processing Personal Data in a way that
              will have a significant impact on you.{' '}
            </Paragraph>
          </Section>

          <Section>
            <SectionHeading>SHARING OF PERSONAL DATA</SectionHeading>
            <Paragraph>
              Jump has executed appropriate documentation designed to protect the privacy and
              fundamental rights and freedoms of Data Subjects and has taken appropriate measures
              designed to ensure data protection during the sharing of Personal Data.
            </Paragraph>
            <SubHeading>a. Third Parties</SubHeading>
            <Paragraph>
              Jump does not sell Personal Data to any third parties. Jump may share Personal Data
              with third parties such as service providers, software applications, or agencies to
              Process (maintain, store, use) on Jump’s behalf for recruiting and other business
              purposes. Jump requires all such Processors to take appropriate security measures to
              protect Personal Data in accordance with Jump’s policies and to maintain adequate
              documentation. Jump does not allow Processors to Process Personal Data for their own
              purposes and only permits them to Process Personal Data for specified purposes and in
              accordance with Jump's instructions. Please email datarequests@jumptrading.com for a
              current list of third parties Processing Personal Data.
            </Paragraph>
            <Paragraph>
              Jump may share Personal Data with third parties to Process on their own behalf. Such
              third parties will be considered joint-Controllers of such Personal Data. While
              joint-Controllers have shared discretion over the purposes of Processing, all such
              Controllers agree to Process such shared Personal Data in accordance with Data
              Protection Laws and shall restrict Processing to the intended business purpose.
            </Paragraph>
            <SubHeading>b. Legal Purposes</SubHeading>
            <Paragraph>
              Jump may also share Personal Data about you if doing so is required or permitted by
              applicable law, including (i) to comply with law enforcement or national security
              requests and legal process, such as a court order or subpoena; (ii) to protect the
              safety, rights, and property of Jump or other persons; (iii) to enforce Jump’s
              policies and contracts; (iv) to collect amounts owed to Jump; or (v) when disclosure
              is necessary or appropriate to prevent financial loss or in connection with an
              investigation or prosecution of suspected or actual illegal activity.
            </Paragraph>
            <SubHeading>c. Cross-Border Data Transfers</SubHeading>
            <Paragraph>
              Jump is a global company and may transfer Personal Data to Jump entities, affiliates,
              and service providers located in such jurisdictions as the United States, United
              Kingdom, European Union, Singapore, or other jurisdictions. Not all jurisdictions
              provide the same level of protection as your country of residence may provide.
              However, Jump shall not transfer Personal Data to a country or territory outside the
              jurisdiction in which it was Processed, except in accordance with the requirements
              prescribed under Data Protection Laws. Jump may transfer or provide access to Personal
              Data across jurisdictions and entities in accordance with its Data Protection
              Agreements and Intercompany Agreements, which are aligned with Data Protection Laws.
              Regarding transfers from the European Economic Area or the United Kingdom, Jump has
              taken steps to provide an adequate level of protection for Personal Data in accordance
              with Data Protection Laws.
            </Paragraph>
          </Section>

          <Section>
            <SectionHeading>PRIVACY RIGHTS OF DATA SUBJECTS</SectionHeading>
            <Paragraph>
              Depending on the applicable data protection and privacy laws and regulations where you
              are located, you may be entitled to exercise some or all of the below Data Subject
              rights, as described herein. In accordance with Data Protection Laws and in certain
              circumstances, a Data Subject may possess the right to:
            </Paragraph>
            <List>
              <ListItem>
                <Italic>Request access</Italic> to his or her Personal Data that Jump holds to check
                that it is accurately and lawfully being Processed. Specifically, in some
                jurisdictions you may request confirmation that we Process your Personal Data and
                ask for: (i) a portable copy of the specific pieces of your Personal Data; (ii) a
                list of categories of Personal Data (and Sensitive Personal Data) we have Processed
                about you; (iii) a list of categories of sources from which such Personal Data was
                Processed; (iv) a list of categories of Personal Data that we Processed or disclosed
                about you; (v) a list of categories of third parties to whom the Personal
                Information was disclosed; (vi) the business purpose for Processing your Personal
                Data; and/or (vii) details about the logic involved in any automated decision-making
                processes used (if applicable), as well as a description of the likely outcome of
                the process with respect to you.
              </ListItem>
              <ListItem>
                <Italic>Request correction</Italic> of his or her Personal Data that Jump holds.
                This enables Data Subjects to have any incomplete or inaccurate Personal Data be
                corrected, though Jump may need to verify the accuracy of any new Personal Data
                provided.
              </ListItem>
              <ListItem>
                <Italic>Request erasure (deletion)</Italic> of his or her Personal Data. This
                enables Data Subjects to ask Jump to delete or remove Personal Data where there is
                no legitimate purpose for the Processing of such Personal Data by Jump. Jump may not
                always be able to comply with the request of erasure for specific legal reasons or
                other legitimate grounds, which will be notified to Data Subjects, if applicable, at
                the time of the request.
              </ListItem>
              <ListItem>
                <Italic>Object to Processing</Italic> of his or her Personal Data where Jump is
                relying on a legitimate interest (or those of a third party) and he or she would
                like to object to the Processing because it impacts his or her fundamental rights
                and freedoms.{' '}
              </ListItem>
              <ListItem>
                <Italic>Request restriction of Processing</Italic> of his or her Personal Data. This
                enables Data Subjects to ask Jump to suspend Processing Personal Data in the
                following scenarios: (a) establishing the accuracy of Personal Data; (b) where
                Jump's use of Personal Data is unlawful, but there is no request of erasure; (c)
                where a Data Subject needs Jump to hold Personal Data even if retention is no longer
                required and it is needed to establish, exercise, or defend a legal claim; or (d) a
                Data Subject objects to Jump's use of Personal Data, but Jump needs to verify
                whether there are overriding legitimate grounds to use it.
              </ListItem>
              <ListItem>
                <Italic>Request the transfer</Italic> of his or her Personal Data. Jump will provide
                to Data Subject, or a third party, his or her Personal Data in a structured,
                commonly used, machine-readable format. This right only applies to automated
                information.
              </ListItem>
              <ListItem>
                <Italic>Withdraw consent</Italic> at any time where Jump is relying on consent to
                Process Personal Data. This will not affect the lawfulness of any Processing carried
                out before consent is withdrawn.{' '}
              </ListItem>
              <ListItem>
                <Italic>Sale/Behavioral Advertising opt-out.</Italic> We do not currently “sell” or
                “share” the Personal Data that is subject to this Policy, as defined under
                applicable Data Protection Laws.
              </ListItem>
              <ListItem>
                <Italic>Opt-out of automated decision-making.</Italic> You may request to opt-out of
                any profiling or automated decision-making in furtherance of decisions that produce
                legal or similarly significant effects concerning you (if applicable).
              </ListItem>
              <ListItem>
                <Italic>Be notified of a data breach</Italic> involving a Data Subject's Personal
                Data.
              </ListItem>
            </List>
            <Paragraph>
              To make a request, please send an email to{' '}
              <Anchor href="mailto:datarequests@jumptrading.com">
                datarequests@jumptrading.com
              </Anchor>{' '}
              or call (312) 205-8900. Jump shall accept and respond to, when applicable, any
              requests through the appropriate channels from an eligible Data Subject to exercise
              his or her rights and freedoms pursuant to Data Protection Laws.{' '}
            </Paragraph>
            <Paragraph>
              Jump shall use reasonable means to verify the identity of the requester, based on the
              nature and sensitivity of the request.{' '}
            </Paragraph>
            <Paragraph>
              You may designate an agent to submit requests on your behalf using the same methods
              described herein. When submitting a request, the agent must identify itself as your
              authorized agent.{' '}
            </Paragraph>
            <Paragraph>
              If a Data Subject exercises any of the above privacy rights, Jump will continue to
              treat the Data Subject fairly and without discrimination.{' '}
            </Paragraph>
            <Paragraph>
              You will not generally have to pay a fee to access your Personal Data or to exercise
              any of the above rights. However, Jump may charge a reasonable fee if the request is
              clearly unfounded or excessive. Jump will try to respond to all legitimate requests
              within thirty (30) days. Occasionally, it may take Jump longer if the request is
              particularly complex. Alternatively, Jump may refuse to comply with the request in
              certain circumstances.{' '}
            </Paragraph>
            <Paragraph>
              Jump hopes that it will be able to address any Data Subjects concerns regarding the
              Processing of Personal Data. However, if you have any unresolved concerns, you have
              the right to complain to the data protection authority in your jurisdiction.{' '}
            </Paragraph>
          </Section>

          <Section>
            <SectionHeading>ADDITIONAL CALIFORNIA PRIVACY DISCLOSURES</SectionHeading>
            <Paragraph>
              For purposes of California Data Protection Law, California residents should note the
              following:{' '}
            </Paragraph>
            <Paragraph>
              Jump may collect and use the following categories of Personal Data for the business
              purposes described in this Policy: Identifiers; Personal Information Categories Listed
              in the California Records Statute; Characteristics of Protected Classifications under
              California or Federal Law; Commercial Information; Internet or Other Network Activity
              Information; and Sensitive Personal Information. Jump may collect these categories of
              Personal Data from certain sources, including directly from you, automatically from
              your device(s), Jump affiliates, and service providers.
            </Paragraph>
            <Paragraph>
              Jump may disclose these categories of Personal Data to the following categories of
              third parties, as more fully described above: Jump affiliates, service providers, and
              agencies or law enforcement for legal compliance purposes.{' '}
            </Paragraph>
            <Paragraph>
              Jump does not “sell” or “share” your Personal Data. We do not sell or share the
              Personal Data of consumers under 16 years of age.
            </Paragraph>
            <Paragraph>
              Jump does not use or disclose Sensitive Personal Data for purposes other than those
              specified under applicable California Data Protection Law.
            </Paragraph>
          </Section>

          <Section>
            <SectionHeading>SECURITY MEASURES TAKEN TO PROTECT PERSONAL DATA</SectionHeading>
            <Paragraph>
              Jump has implemented appropriate elements of privacy by design in conjunction with
              technical and physical safeguards designed to protect the security of Personal Data
              from unauthorized or unlawful Processing.{' '}
            </Paragraph>
            <Paragraph>
              In assessing the appropriate level of security as well as the risks of varying
              likelihood and severity for the rights and freedoms of Data Subjects, Jump assesses
              the risks presented by the Processing of Personal Data. Such risks may include, but
              are not limited to, any accidental, unlawful, or unauthorized destruction, loss,
              disclosure, alteration, or access to Personal Data Processed by or on behalf of Jump,
              or other factors that may impact Data Subject rights and freedoms. Jump shall make
              reasonable attempts to ensure that any risks presented by the Processing of Personal
              Data are sufficiently mitigated by technological and/or organizational controls,
              including limited access of Personal Data utilizing access controls and password
              protections. However, nobody can guarantee that data transmitted through or stored on
              the Internet will be 100% secure all the time.{' '}
            </Paragraph>
          </Section>

          <Section>
            <SectionHeading>RETENTION OF PERSONAL DATA</SectionHeading>
            <Paragraph>
              If Jump needs to Process your Personal Data for a purpose unrelated to those listed
              above, Jump will, as required by applicable law, notify you and explain the related
              legal basis for such Processing. Jump will only retain your Personal Data (including
              your Sensitive Personal Information) for as long as is reasonably necessary for the
              purpose for which the Personal Data was collected, or as legally required. We will not
              hold or Process your Personal Data for any longer than we are legally permitted to.
              The criteria used to determine the appropriate retention period includes:
            </Paragraph>

            <List>
              <ListItem>
                Regulatory requirements that we are subject to, including laws and regulations
                related to tax, employment, accounting, and securities;
              </ListItem>
              <ListItem>
                Whether a legal claim might be brought against us, for which the information would
                be relevant;
              </ListItem>
              <ListItem>
                The necessity of the information to provide our services, and whether other
                information may suffice; and
              </ListItem>
              <ListItem>
                The types and sensitivity of personal information being Processed.
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionHeading>ACCOUNTABILITY AND GOVERNANCE</SectionHeading>
            <SubHeading>a. Inventory</SubHeading>
            <Paragraph>
              For each Process, Jump shall maintain an inventory to capture the business function,
              purpose of Processing, categories of Data Subjects, Personal Data type, access,
              technical and organizational security measures, applicable Data Protection Laws, Data
              Subject rights, source of Personal Data, the use of automated decision-making,
              location of Personal Data, and whether a data protection impact assessment was
              performed. This Personal Data inventory shall be reviewed on at least an annual basis
              and updated when new Processes are implemented as well as provided to supervisory
              authorities upon request.{' '}
            </Paragraph>

            <SubHeading>b. Data Protection Impact Assessment</SubHeading>
            <Paragraph>
              Jump has completed a risk assessment for each Process to identify any potentially
              high-risk areas to Data Subjects or the Processing of Personal Data. A Process may be
              classified as high risk as a result of the Processing of (i) Special Category Data;
              (ii) a large volume of Personal Data; (iii) Personal Data using automated processing
              or profiling; or (iv) Personal Data using new internal technologies. Controllers that
              engage in high-risk Processing shall conduct data protection impact assessments
              (DPIAs) for such Processes. Jump has conducted a DPIA for each Process designated as
              high risk. Such DPIAs outline the measures taken to address each identified risk to
              the rights and freedoms of Data Subjects and may be provided to supervisory
              authorities upon request.
            </Paragraph>

            <SubHeading>c. Data Protection Officer</SubHeading>
            <Paragraph>
              Jump has designated Alex Davies as its Data Protection Officer (“DPO”). In his
              capacity as Jump’s DPO, Alex is responsible for informing and advising Jump and its
              Personnel on its data protection obligations and overseeing firm-wide compliance with
              Data Protection Laws. The DPO shall aid in training all personnel as needed, at least
              on an annual basis. The DPO shall act as the point of contact for and shall cooperate
              with supervisory authorities. Alex can be contacted directly via email at
              adavies@jumptrading.com.
            </Paragraph>
          </Section>

          <Section>
            <SectionHeading>THIRD PARTY WEBSITES AND SOCIAL MEDIA </SectionHeading>
            <Paragraph>
              Our websites may contain content and links to third party websites that are not owned,
              operated, or controlled by Jump. Jump is not responsible for the privacy practices of
              or the content displayed on such third party websites. When engaging with Jump’s
              content on or through a third party social networking website, plug-in, or
              application, Jump may Process Personal Data associated with your social media account.
            </Paragraph>
          </Section>

          <Section>
            <SectionHeading>REVIEW AND UPDATES TO POLICY </SectionHeading>
            <Paragraph>
              Jump will review and may update this Policy to reflect changes to Jump’s privacy
              practices or security measures as needed. If a review is not satisfactory, Jump will
              take immediate steps to remedy any noted deficiencies. Please periodically review this
              Policy for the latest on Jump’s privacy practices. The use of our websites after any
              updates constitutes an acknowledgement of having read and understood the Policy.{' '}
            </Paragraph>
          </Section>

          <Section>
            <SectionHeading>CONTACT </SectionHeading>
            <Paragraph>
              Please contact Jump by email at&nbsp;
              <Anchor href="mailto:datarequests@jumptrading.com">
                datarequests@jumptrading.com
              </Anchor>
              &nbsp;should you have any questions or comments about this Policy or your Personal
              Data.{' '}
            </Paragraph>
          </Section>
        </CenteredResponsiveContainer>
      </section>
    </Layout>
  )
}
